<!--查看转寄记录信息-->
<template>
<el-form ref="form" :model="expResend" label-width="100px">
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('订单编号')">
                <el-input v-model="expResend.orderCode"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('上架号')">
                <el-input v-model="expResend.shelfCode"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row style="margin:10px 0px;">
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('品名')">
                <el-input v-model="expResend.goodName"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('价格')">
                <el-input v-model="expResend.price"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row style="margin:10px 0px;">
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('规格')">
                <el-input v-model="expResend.spec"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('数量')">
                <el-input v-model="expResend.qty"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row style="margin:10px 0px;">
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('备注')">
                <el-input v-model="expResend.remark"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <div style="text-align:center;padding-top: 20px;">
        <el-button  size="mini" v-on:click="$emit('closeDg')">{{msg('关闭')}}</el-button>
    </div>
</el-form> 
</template>

<script>
export default {
  name: 'ExpResendEdit',
  data(){
    return{
       storePkg:null,
    }
  }, 
  computed:{
  },
  props:{
      id:null,
      expResend:Object,
  },
  mounted: function(){
        var _this = this;
  },
  methods:{
    onSubmit(){

        var _this = this;
        var url = null;
        if(this.id==null){
            url = this.$kit.api.expBackCreate;
        }else{
            url = this.$kit.api.expBackUpdate;
        }

        this.$axios.post(url, this.expBack)
            .then(function(result){
                if(result.status){
                    _this.$alert(result.msg || (_this.id==null? _this.msg("创建成功"): _this.msg('更新成功')), _this.msg('提示'), {
                        confirmButtonText: _this.msg('确定'),
                        callback: action => {
                            if(result.status){
                                _this.$emit("closeDg", true);
                            }
                        }
                    });
                }else{
                    _this.$message(result.msg || (_this.id==null? _this.msg('创建失败'):_this.msg('更新失败')));
                }
               
            });

    }
  }
}
</script>

<style class="scss" scoped>
.el-form-item{
    margin-bottom: 0;
}

</style>
