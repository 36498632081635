
<!-- 重出转寄的找货单-->
<template>
    <div class="print-body"  v-if="datas" style="background: white;padding:15px;">
        <div class="tb2-wrap" style="margin:15px 0px;">
            <table class="tb2" >
                <thead>
                    <tr class="th2">
                        <th class="th21">上架碼</th>
                        <th class="th22">上架號</th>
                        <th class="th22">货单号(新)</th>
                        <th class="th23">配送方式(舊)</th>
                        <th class="th24">收件人(舊)</th>
                        <th class="th25">品名(新)</th>
                        <th class="th26">規格(新)</th>
                        <th class="th26">收件人(新)</th>
                        <th class="th27">類型</th>
                        <th class="th27">圖片</th>
                    </tr>
                </thead>
                <tbody v-if="datas">
                    <tr class="td2" v-for="(order, idx) in datas" :key="idx">
                        <td class="td21">{{order.upperCode}}</td>
                        <td class="td22">{{order.storeShelfCode}}</td>
                        <td class="td22">{{order.oOrderCode}}</td>
                        <td class="td23">{{order.oexpCompany}}</td>
                        <td class="td24">{{order.oreceiver}}</td>
                        <td class="td25">{{order.nname}}</td>
                        <td class="td26">{{order.nspec}}</td> 
                        <td class="td27">{{order.nreceiver}}</td>
                        <td class="td27">{{dicts.expPkgTypes[order.pkgType]}}</td>
                        <td class="td27">{{order.productPicId ? '有' : '無'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

export default {
  name: 'ExpResendFindTicketTpl',
  data(){
    return{}
  },
  props:{
      datas: Array,
  },
  mounted: function(){},
  methods:{}
}
</script>

<style class="scss" scoped>

.print-body table{width:100%;}
.print-body table th{font-size:12px; text-align:center;   
}
.print-body table thead th{border-right: 1px solid #8a8a8a; text-align:center; font-size:16px; font-weight:bold;}
.print-body table{border-top: 1px solid #8a8a8a; border-left: 1px solid #8a8a8a; 
  border-collapse:collapse; border-spacing: 0px; 
} 
.print-body table tbody td{
  border-top: 1px solid #8a8a8a; 
  border-bottom: 1px solid #8a8a8a;
  border-right: 1px solid #8a8a8a;
  font-size:14px;
}

.tb1-wrap, .tb2-wrap{margin:0px auto;  }

</style>
