<!--转寄记录列表页面-->
<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('转寄记录')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="4" style="text-align:right;">
      <el-button type="success" size="small" icon="el-icon-refresh" @click="refresh()" circle></el-button>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" :style="{height: isQueryExpend?'auto':'55px'}">
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('旧单号')" v-model="query.oldCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('新单号')" v-model="query.newCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('转寄人')" v-model="query.createrName" size="small">
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
       <el-date-picker  v-model="query.Date"  type="daterange"  :range-separator="msg('至')" 
            :start-placeholder="msg('转寄时间')"  value-format="yyyy-MM-dd"
            end-placeholder=""
            size="small"
          >
        </el-date-picker>
    </el-col>
  </el-row>

  <vxe-toolbar id="toolbar_demo3" :custom="{storage: true}">
    <template v-slot:buttons>
      <el-button-group>
        <!-- <el-button type="primary" size="mini" @click="view()" v-if="hasPermission('expResend:view')">
          <i class="el-icon-circle-plus-outline" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('查看')}}</span>
          </transition>
        </el-button>
        <el-button type="success" size="mini" @click="notifyUpPage(1)" v-if="hasPermission('expResend:allowUpload')">
          <i class="el-icon-bell" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('通知上传面单')}}</span>
          </transition>
        </el-button>
        <el-button type="success" size="mini" @click="notifyUpPage(0)" v-if="hasPermission('expResend:allowUpload')">
          <i class="el-icon-bell" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('取消通知上传面单')}}</span>
          </transition>
        </el-button>        
        <el-button type="primary" size="mini" @click="print()" v-if="hasPermission('expResend:tickets')">
          <i class="el-icon-printer" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('打印货单')}}</span>
          </transition>
        </el-button>
        <el-button type="primary" size="mini" @click="printFinds()" v-if="hasPermission('expResend:finderTickets')">
          <i class="el-icon-takeaway-box" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('打印找货单')}}</span>
          </transition>
        </el-button> -->
        <el-button type="success" size="mini" @click="notifyStatus(1)" v-if="hasPermission('expResend:delete')">
          <i class="el-icon-bell" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('更新为已转寄')}}</span>
          </transition>
        </el-button>
        <el-button type="warning" size="mini" @click="notifyStatus(0)" v-if="hasPermission('expResend:delete')">
          <i class="el-icon-bell" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('更新为未转寄')}}</span>
          </transition>
        </el-button>
        <el-button type="danger" size="mini" @click="deleted()" v-if="hasPermission('expResend:delete')">
          <i class="el-icon-delete" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('删除')}}</span>
          </transition>
        </el-button>      
      </el-button-group>
    </template>
    <template slot="tools">
      <el-button-group>
        <el-button type="success" @click="getExpResends(true)" size="mini">
          <i class="el-icon-search" style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('搜索')}}</span>
          </transition>
        </el-button>
        <el-button type="info" size="mini" @click="expendQuery()">
          <i class="el-icon-arrow-down" v-if="!isQueryExpend" style="display:inline-block;margin-right:4px; "></i>
          <i class="el-icon-arrow-up"  v-else style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('高级')}}</span>
          </transition>
        </el-button>
        <el-button type="success" size="mini" @click="$refs.dataTable.exportData({ type: 'xlsx',filename: msg('转寄记录下载'),sheetName: 'Sheet1' })" >
          <i class="el-icon-download" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('导出')}}</span>
          </transition>
        </el-button>
      </el-button-group>
    </template>
  </vxe-toolbar>
  
  <div class="table-wrap">
    <vxe-table style="width: 100%" :height="docHeight" ref="dataTable"
      border="full" size="small"  stripe resizable  :data="expResends" 
      show-overflow 
      align="center"
      :span-method="mergeRowMethod"
      highlight-hover-row highlight-current-row highlight-hover-column highlight-current-column
    >
      <vxe-table-column type="checkbox"  min-width="55" :fixed="isMinScreen?null: 'left'"></vxe-table-column>
      <vxe-table-column  field="id" :title="msg('ID')" min-width="70" :fixed="isMinScreen?null: 'left'"
      ></vxe-table-column>
      <vxe-table-column field="errId" :title="msg('退件ID')" min-width="150" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column field="shelfCode" :title="msg('货架号')" min-width="150" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column field="oldCode" :title="msg('旧单号')" min-width="150" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column field="status" :title="msg('状态')" min-width="100" 
        sortable
      >
        <template v-slot="{ row }">
          <el-tag :type="dicts.cssTypes[row.status]" disable-transitions size="medium" style="font-size:15px;">
            {{dicts.storeErrStatus[row.status]}}
          </el-tag>
        </template>
      </vxe-table-column>
      <vxe-table-column field="newCode" :title="msg('新单号')" min-width="150" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column  field="fullname" :title="msg('转寄人')" min-width="120" :show-overflow="true"
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column field="resendDate" :title="msg('转寄时间')" min-width="150"
         sortable :filters="[{data: []}]" :filter-render="{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}"
      ></vxe-table-column>
    
    </vxe-table>

    <el-pagination id="xpagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :background="true"
      :current-page.sync="currentPage"
      :page-sizes="[10,20 ,50, 100, 200, 500, 1000,2000, 5000,10000,20000,50000]"
      :page-size="query.length"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>

  <el-dialog :title="dglogTitle" :visible.sync="dialogTableVisible" :fullscreen="isMinScreen" width="800px">
    <exp-resend-edit :id="currId" :expResend="currBack" v-on:closeDg="closeEditDialog" ref="shelfEdit"/>
  </el-dialog>
  <el-image-viewer v-if="showViewer" :on-close="()=>{this.showViewer = false}" :url-list="[imgUrl]" />
  
   <vue-easy-print tableShow ref="easyPrint">
    <template>
      <ticket-tpl :datas="tickets"></ticket-tpl>
    </template>
  </vue-easy-print>

  <vue-easy-print tableShow ref="easyPrint1">
    <template>
      <find-ticket-tpl :datas="tickets" ></find-ticket-tpl>
    </template>
  </vue-easy-print>

  <el-dialog :title="msg('订单详情')" :visible.sync="orderVisiable" :fullscreen="isMinScreen"  width="800px" >
    <div>
      <el-table :data="orders" style="width: 100%" height="300px" size="mini" ref="orderTable" stripe>
        <el-table-column  prop="id" :label="msg('ID')" min-width="90"></el-table-column>
        <el-table-column  prop="orderCode" :label="msg('退件转寄单号')" min-width="150"></el-table-column>
        <el-table-column  prop="name" :label="msg('商品名')" min-width="150"></el-table-column>
        <el-table-column  prop="spec" :label="msg('规格')" min-width="120"></el-table-column>
        <el-table-column  prop="qty" :label="msg('件数')" min-width="70"></el-table-column>
        <el-table-column  prop="picId" :label="msg('图片')" min-width="70">
          <template slot-scope="scope">
            <el-image style="width: 45px; height: 45px"
              :src="imgView + scope.row.picId"  :preview-src-list="[imgView + scope.row.picId]">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column  prop="remark" :label="msg('备注')" min-width="120"></el-table-column>
      </el-table>
    </div>
  </el-dialog> 

  <el-image-viewer v-if="showViewer" :on-close="()=>{this.showViewer = false}" :url-list="[imgUrl]" />
</div>
</template>

<script>
// @ is an alias to /src
import ExpResendEdit from "./ExpResendEdit.vue";
import vueEasyPrint from "vue-easy-print";
import FindTicketTpl from "./ExpResendFindTicketTpl.vue";
import TicketTpl from "./ExpResendTicketTpl.vue";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import XEUtils from 'xe-utils';

export default {
  name: 'ExpResend',
  components: {
    ExpResendEdit:ExpResendEdit,
    vueEasyPrint: vueEasyPrint,
    FindTicketTpl: FindTicketTpl,
    TicketTpl: TicketTpl,
    ElImageViewer: ElImageViewer,
  },
  mounted:function(){
    this.getExpResends();
  },
  data(){
    return{
        query:{  //查询条件
          start: 0,
          length: 10,
          oldCode:null,
          newCode:null,
          createrName:null,
          Date:[],
        },
        currentPage: 1,   //分页的当前页
        expResends: [],   //表格数据列表
        dglogTitle: this.msg('编辑信息'),
        isQueryExpend: this.$store.state.isQueryExpend,  //查询框是否展开
        total: 0,//总记录数,
        currId: null,   //当前列表中选中的id
        dialogTableVisible: false, //编辑详情的隐藏与显示,
        currBack:null,
        tickets: null,

        imgView: this.$kit.api.imgView,  //图片显示
        showViewer:false,
        imgUrl: null,

        orderVisiable: false,
        orders: [],

        printMsg:null,
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
      get() {
        return this.$store.state.isMinScreen;
      }
    },
    docHeight: {  //表格的高度
      get() {
        var height =  this.$store.state.docHeight;
        return height - 320;
      }
    }
  },
  methods: {
    mergeRowMethod ({ row, _rowIndex, column, visibleData }) {
      const fields = ['id','newCode']
      const cellValue = XEUtils.get(row, column.property)
      if (cellValue && fields.includes(column.property) && visibleData) {
        const prevRow = visibleData[_rowIndex - 1]
        let nextRow = visibleData[_rowIndex + 1]
        if (prevRow && XEUtils.get(prevRow, column.property) === cellValue) {
          return { rowspan: 0, colspan: 0 }
        } else {
          let countRowspan = 1
          while (nextRow && XEUtils.get(nextRow, column.property) === cellValue) {
            nextRow = visibleData[++countRowspan + _rowIndex]
          }
          if (countRowspan > 1) {
            return { rowspan: countRowspan, colspan: 1 }
          }
        }
      }
    },
    getExpResends: function(){ //获取列表
      var _this = this;

      var param = this.$kit.rebuildQuery(this.query);

      this.axios.post(this.$kit.api.expResendList, param).then(function (result) {
        if(result.status){
          if(_this.expResends.length>0)
            _this.expResends.splice(0, _this.expResends.length);
          
          _this.total = result.data.recordsTotal;
          var datas = result.data.data;

          for(var i=0; i<datas.length; i++){
            var item = datas[i];

            _this.expResends.push(item);
          } 
        }else{
          _this.$message(result.msg);
        }
        
      }).catch(function (error) {
        console.error(error);
      });

    },//end function
    notifyUpPage(status){//允许上传面单
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }

      var ids = selectes.map(e => e.expId);
      this.axios.get(this.$kit.api.expResendNotify+"?ids="+ids.join(",")+"&s="+status).then((result) => {
        this.$message(result.msg);
        if(result.status){
          this.getExpResends();
        }
      }).catch(function (error) {
        console.error(error);
      });

    },
    notifyStatus(status){//更新转寄状态
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      var _this = this;
      var ids = selectes.map(e => e.id);
       this.axios.get(this.$kit.api.expResendNotifyStatus+"?ids="+ids.join(",")+"&s="+status).then((result) => {
        this.$message(result.msg);
        if(result.status){
          _this.getExpResends();
        }
      }).catch(function (error) {
        console.error(error);
      });
    },
    refresh: function(){  //刷新列表
      this.$kit.clearQuery(this.query);
      this.currentPage = 1;
      this.getExpResends();
    },
    expendQuery: function(){  //展开与收缩查询框
        this.isQueryExpend = !this.isQueryExpend;
    },
    handleSizeChange(val) {  //分页的每页数据变化后的回调事件
      //console.log(`每页 ${val} 条`);
      this.query.length = val;
      this.query.start = 0;
      this.getExpResends();
    },
    handleCurrentChange(val) {   //分页的当前页改变后的回调事件
      //console.log(`当前页: ${val}`);
      this.query.start = this.query.length * (val -1);
      this.getExpResends();
    },
    view(){  //修改
      var _this = this;
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      if(selectes.length > 1){
        this.$message(this.msg('只能查看一条数据'));
        return;
      }
      _this.currBack = selectes[0];
      _this.currId = _this.currBack.id;
      _this.dglogTitle = this.msg("查看转寄信息");
      _this.dialogTableVisible = true;

    },
    //删除
    deleted(){
        var selectes = this.$refs.dataTable.getCheckboxRecords();
        if(selectes.length==0){
          this.$message(this.msg('请选中一条数据进行操作'));
          return;
        }

        var ids = selectes.map( o => o.id);
        var _this = this;

        this.$confirm(this.msg('确定要删除该物流信息吗?'), this.msg('提示'), {
            confirmButtonText: this.msg('确定'),
            cancelButtonText: this.msg('取消'),
            type: 'warning'
          }).then(() => {
            _this.$axios.post(_this.$kit.api.expResendDelete+"?ids="+ids)
              .then(function(result){
                _this.$message(result.msg || _this.msg('删除成功'));
                _this.getExpResends();
            });
          });
    },
    closeEditDialog(type){  //编辑弹窗关闭后的回调事件
      if(type){
        this.getExpResends();
      }
      this.dialogTableVisible = false;
    },
    openPdfPage(expPageId){
      this.imgUrl = this.imgView+expPageId;
      this.showViewer = true;
    },

    print(){
      var _this = this;
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      _this.printMsg = "请输入袋号";
      this.inputPkgCode((pkgCode) => {
        this.axios.get(this.$kit.api.resend.tickets+"?pkgCode="+pkgCode+"&expIds="+selectes.map(e => e.expId).join(",")+"&ids="+selectes.map(e => e.id).join(",")).then(function (result) {
          _this.$message(result.msg);
          if(result.status){
            _this.tickets = result.data;
            setTimeout(()=>{
              _this.$refs.easyPrint.print();
            }, 500);
            _this.getExpResends();
          }
        }).catch(function (error) {
          console.error(error);
        });
      });
     
    },
    printFinds(){
      // var _this = this;
      // _this.printMsg = "请输入要打印的包号";
      // this.inputPkgCode((pkgCode) => {
      //    this.axios.get(this.$kit.api.resend.finderTickets+"?pkgCode="+pkgCode).then(function (result) {
      //       _this.$message(result.msg);
      //       if(result.status){
      //         _this.tickets = result.data;
      //         if(result.data.length>0){
      //           setTimeout(()=>{
      //             _this.$refs.easyPrint1.print(); 
      //           }, 500);
      //         }else{
      //           _this.$message("没有可打印的数据");
      //         }
            
      //       }
      //     }).catch(function (error) {
      //       console.error(error);
      //     });
      // });
        var _this = this;
        var selectes = this.$refs.dataTable.getCheckboxRecords();
        if(selectes.length==0){
          this.$message(this.msg('请选中一条数据进行操作'));
          return;
        }
        this.axios.get(this.$kit.api.resend.finderTickets+"?ids="+selectes.map(e => e.id).join(",")).then(function (result) {
            _this.$message(result.msg);
            if(result.status){
              _this.tickets = result.data;
              if(result.data.length>0){
                setTimeout(()=>{
                  _this.$refs.easyPrint1.print(); 
                }, 500);
              }else{
                _this.$message("没有可打印的数据");
              }
            
            }
          }).catch(function (error) {
            console.error(error);
          });

    },
    inputPkgCode(callback){
      var _this = this;
      _this.$prompt(_this.msg(_this.printMsg), _this.msg('提示'), 
      {confirmButtonText: _this.msg('确定'), cancelButtonText: _this.msg('取消'),
        inputPattern: /^[a-zA-Z0-9]{0,20}$/,
        inputErrorMessage: _this.msg('袋号只能是字母和数字，或者字母加数字组合，不能有空格，长度只能是：4-20')
      }).then(({ value }) => {
        callback(value);
      }).catch(() => {
        _this.$message({type: 'info', message: '取消操作'});       
      });
    },
    getOrders(exp){
      this.axios.get(this.$kit.api.resend.detail+"?id="+exp.expId)
        .then( (result) => {
          if(result.status){
            this.orders = result.data;
            this.orderVisiable = true;
          }else{
            this.$message(result.msg);
          }
        }).catch(function (error) {
          console.error(error);
        });
       
    },
  }
}
</script>

<style class="scss" scoped>

</style>
