<!--重出专转寄的货单-->
<template>
    <div class="print-body"  v-if="datas" style="background: white;padding:15px;">
        <div class="custs" v-for="(data, idx) in datas" :key="idx" >
            <div class="exp-type">{{data.expCompany}}</div>
            <div class="area1">
                <img :src="'data:image/png;base64,'+data.orderCodeAsBase64" />
                <div>{{data.orderCode}}</div>
            </div>

            <div class="tb1-wrap">
                <table class="tb1">
                    <thead>
                        <tr class="th1">
                            <th class="th11">上架碼</th>
                            <th class="th12">上架號</th>
                            <th class="th13">新貨品名</th>
                            <th class="th14">類型</th>
                            <th class="th14">備註</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="td1" v-for="(ticket, jdx) in data.tickets" :key="jdx">
                            <td class="td11"><span>{{ticket.upperCode}}</span></td>
                            <td class="td12"><span>{{ticket.shelfCode}}</span></td>
                            <td class="td13">{{ticket.goodName}}</td>
                            <td class="td14">{{dicts.expPkgTypes[ticket.pkgType]}}</td>
                            <td class="td15">{{ticket.remark}}</td>
                        </tr>
                    </tbody> 
                </table>
            </div>
            <div v-if="datas.length>0 && idx < datas.length-1" style="page-break-after: always;"></div>
        </div><!--end custs-->
       
    </div>
</template>

<script>

export default {
  name: 'ExpResendTicketTpl',
  data(){
    return{}
  },
  props:{
      datas: Array,
  },
  mounted: function(){},
  methods:{}
}
</script>

<style class="scss" scoped>
.print-body{text-align:center; width: 400px;}
.exp-type{font-size: 18px; font-weight:bold;}
.area1 img{width:200px;}
.area1 div{font-size:16px; margin-top:5px; margin-bottom: 15px;}
.area1 {margin-bottom:15px; border-bottom:2px dashed #000;}

.print-body table{width:100%;}
.print-body table th{font-size:12px; text-align:center;   
}
.print-body table thead th{border-right: 1px solid #8a8a8a; text-align:center; font-size:16px; font-weight:bold;}
.print-body table{border-top: 1px solid #8a8a8a; border-left: 1px solid #8a8a8a; 
  border-collapse:collapse; border-spacing: 0px; 
} 
.print-body table tbody td{
  border-top: 1px solid #8a8a8a; 
  border-bottom: 1px solid #8a8a8a;
  border-right: 1px solid #8a8a8a;
  font-size:14px;
}

.tb1-wrap, .tb2-wrap{margin:0px auto;  }

</style>
